/* Black */
@font-face {
    font-family: 'Gotham';
    src: url('../assets/font-family/Gotham-Black.otf') format('opentype');
    font-weight: 900; /* 'black' */
    font-style: normal;
}

/* Bold */
@font-face {
    font-family: 'Gotham';
    src: url('../assets/font-family/Gotham-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

/* Book */
@font-face {
    font-family: 'Gotham';
    src: url('../assets/font-family/Gotham-Book.otf') format('opentype');
    font-weight: 400; /* 'normal' or 'book' */
    font-style: normal;
}

/* Medium */
@font-face {
    font-family: 'Gotham';
    src: url('../assets/font-family/Gotham-Medium.otf') format('opentype');
    font-weight: 500; /* 'medium' */
    font-style: normal;
}

/* Light */
@font-face {
    font-family: 'Gotham';
    src: url('../assets/font-family/Gotham-Light.otf') format('opentype');
    font-weight: 300; /* 'light' */
    font-style: normal;
}

/* Extra Light */
@font-face {
    font-family: 'Gotham';
    src: url('../assets/font-family/Gotham-XLight.otf') format('opentype');
    font-weight: 200; /* 'extra-light' or 'ultra-light' */
    font-style: normal;
}


.input-text::placeholder {
    font-family: 'Gotham';
    font-weight: 500;
    color: '#A6A6A6'
}
.input-text{
    font-family: 'Gotham';
}