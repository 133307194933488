
.fade-in {
    opacity: 0;
    transform: translateX(-100px); /* Starts 100px to the left */
    animation: slideIn 1.5s ease-out forwards;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.login:hover,
.signup:hover {
    background-color: #4a4a4a !important;
}
.login {
    transition: background-color 0.3s ease;
}

.signup {
    transition: background-color 0.3s ease;
}